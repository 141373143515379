import React from "react";
// import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import { urls } from "../menus";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { DataProvider, getImage } from "../contentful/data-provider";
import { Disclosure } from "@headlessui/react";
import Seo from "../components/seo";
import VideoWithPopUpSection from "../components/sections/neuro-powered-marketing";

function InnovationSingle({ data }) {
	const innovation = data.contentfulInnovation;
	const nextInnovation = data.next;
	const previousInnovation = data.previous;

	const getInnovation = (innovation) => {
		if (innovation === null) {
			return { title: "", slug: "", excerpt: { raw: "" } };
		}
		return innovation;
	};
	const dataProvider = new DataProvider(data);

	const checkmark = require("../images/checkmark.svg").default;
	return (
		<Layout dataProvider={dataProvider}>
			<Seo
				dataProvider={dataProvider}
				title={`GlassView | ${dataProvider.getLabel("innovation")} - ${innovation.title}`}
				description={innovation.metaDescription}
			/>
			<main className={"pt-16 lg:pt-24"}>
				<section className='pt-10 pb-0 md:pt-24'>
					<div className={`container`}>
						<div className='innovation-details-section flex flex-wrap border-b border-[#D8D8D8] pb-0 md:pb-[80px]'>
							<div className='md:pr-[104px] basis-full lg:basis-1/2'>
								<Link to={urls.innovation} className='flex items-center mb-4 space-x-3 font-bold uppercase'>
									<svg className='mb-[2px]' width='17' height='13' xmlns='http://www.w3.org/2000/svg'>
										<path
											d='m7.272 12.333.258-.022a6.59 6.59 0 0 0-.266-1.19 7.675 7.675 0 0 0-.45-1.107 6.868 6.868 0 0 0-1.418-1.926 5.826 5.826 0 0 0-1.248-.898h11.423V6.12H4.148a5.822 5.822 0 0 0 1.248-.9 6.87 6.87 0 0 0 1.418-1.925c.178-.357.328-.726.45-1.108.122-.38.213-.773.265-1.187H6.145c-.023.276-.068.56-.137.841a6.438 6.438 0 0 1-.31.951 5.722 5.722 0 0 1-1.066 1.684 4.693 4.693 0 0 1-1.625 1.149c-.31.132-.637.232-.973.305A6.928 6.928 0 0 1 1 6.053v1.203c.35.017.696.055 1.034.123.336.073.663.173.973.305.623.262 1.174.66 1.625 1.149a5.722 5.722 0 0 1 1.065 1.684c.129.309.233.628.31.951.072.289.118.582.14.865h1.125Z'
											fill='#3D2EFF'
											stroke='#3D2EFF'
											fillRule='evenodd'
										/>
									</svg>
									<span>{dataProvider.getLabel("innovation")}</span>
								</Link>
								<h1 className='text-black h2'>{innovation.title}</h1>
								{ !innovation.youtubeId &&
									<img
										className='block mt-8 lg:mt-0 md:hidden'
										src={getImage(innovation.image).url}
										alt={getImage(innovation.image).title}
									/>
								}
								<div className={"mt-[35px] lg:mt-[67px] mb-14 innovation-text"}>
									{ innovation.youtubeId &&
										<VideoWithPopUpSection
											className='mb-10 small-video'
											title='Neuro-powered Marketing'
											videoID={innovation.youtubeId}
										/>}
									{renderRichText(innovation.body)}
								</div>
							</div>
							<div className='hidden basis-full lg:basis-1/2 md:block lg:mt-12'>
								<img src={getImage(innovation.image).url} alt={getImage(innovation.image).title} className={"mx-auto"} />
							</div>
						</div>
					</div>
				</section>

				<section className='pt-14 pb-14 innovation-benefits-section'>
					<div className='container mx-auto'>
						<div className='flex flex-wrap items-center justify-between min-h-[90px] space-y-10 lg:space-y-0'>
							<div className='basis-auto'>
								<h2 className='benefits-title basis-full lg:basis-1/3'>{dataProvider.getLabel("key-benefits")}:</h2>
							</div>
							{innovation.benefits.map((benefit, index) => {
								return (
									<div className='basis-full lg:basis-1/3' key={index}>
										<div className='flex items-center'>
											<img className='w-auto mr-6' src={checkmark} alt={benefit.title} />
											<p className='benefits-text'>{benefit.title}</p>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</section>

				<section className={"py-0 faq-section"}>
					<div className={"container accordion-full-width"}>
						<div className='flex p-[30px] md:p-12 bg-[#EFF3F4] md:rounded-[20px]'>
							<div className='flex flex-col flex-1'>
								{innovation.faqs.map((faq, index) => {
									return (
										<Disclosure>
											{({ open }) => (
												<div className='accordion-border'>
													<Disclosure.Button className={"w-full"}>
														<div className='flex items-center justify-between p-5'>
															<h3 className='text-left mr-7 accordion-title'>{faq.question}</h3>
															<svg
																className={`mt-1 shrink-0 ${open ? "rotate-180 transform" : ""}`}
																width='16'
																height='10'
																xmlns='http://www.w3.org/2000/svg'
															>
																<path
																	d='M15 1.313 14.972 1a8.249 8.249 0 0 0-1.469.324c-.47.148-.927.33-1.367.547a8.478 8.478 0 0 0-2.38 1.724 7.09 7.09 0 0 0-1.11 1.518L8 6.536l-.676-1.423a7.086 7.086 0 0 0-1.11-1.518 8.48 8.48 0 0 0-2.38-1.724 9.577 9.577 0 0 0-1.367-.547A8.26 8.26 0 0 0 1 1v1.685c.34.027.692.082 1.039.166a8.04 8.04 0 0 1 1.175.378 7.084 7.084 0 0 1 2.08 1.296 5.71 5.71 0 0 1 1.419 1.976c.163.378.287.775.377 1.184.084.41.131.832.152 1.258h1.486a8.32 8.32 0 0 1 .152-1.258c.09-.409.214-.806.377-1.184a5.71 5.71 0 0 1 1.419-1.976 7.084 7.084 0 0 1 2.08-1.296 8.04 8.04 0 0 1 1.175-.378A6.683 6.683 0 0 1 15 2.682V1.313Z'
																	fill='#000'
																	stroke='#000'
																	stroke-width='.5'
																	fill-rule='evenodd'
																/>
															</svg>
														</div>
													</Disclosure.Button>
													<Disclosure.Panel>
														<div className='flex flex-wrap items-center justify-between px-5 pb-5'>
															{renderRichText(faq.answer)}
														</div>
													</Disclosure.Panel>
												</div>
											)}
										</Disclosure>
									);
								})}
							</div>
						</div>
					</div>
				</section>

				<section className='py-5 md:pt-20 md:pb-[50px] innovation-benefits-section'>
					<div className='container '>
						<div className='flex flex-wrap mx-auto md:p-2'>
							{/* ARROW-LEFT */}
							<div
								className={
									"flex items-center justify-between  border-b lg:border-b-0 lg:border-r border-[#E3E3E3] basis-full lg:basis-1/2"
								}
							>
								<Link className='w-full' to={urls.innovation + getInnovation(previousInnovation).slug}>
									<div className='flex flex-row-reverse items-center justify-between md:flex-row'>
										{/* SVG */}
										<div className='max-w-[44px] rotate-180 md:rotate-0'>
											<svg
												className='h-full w-[37px] md:w-[64px] shrink-0'
												xmlns='http://www.w3.org/2000/svg'
												viewBox='0 0 64 45'
											>
												<path
													d='m27.351 44.5 1.124-.087a23.22 23.22 0 0 0-1.16-4.617 28.022 28.022 0 0 0-1.962-4.299c-1.559-2.76-3.615-5.33-6.182-7.477a25.55 25.55 0 0 0-5.443-3.489h49.815v-4.157H13.728a25.534 25.534 0 0 0 5.443-3.488c2.567-2.148 4.623-4.717 6.182-7.477a28.055 28.055 0 0 0 1.961-4.3A23.25 23.25 0 0 0 28.474.5h-6.038a18.67 18.67 0 0 1-.598 3.265 23.106 23.106 0 0 1-1.354 3.693c-1.125 2.397-2.674 4.642-4.647 6.538a20.62 20.62 0 0 1-7.083 4.458 25.775 25.775 0 0 1-4.245 1.187c-1.472.264-2.982.41-4.509.476v4.672a33.84 33.84 0 0 1 4.509.475 25.85 25.85 0 0 1 4.245 1.188 20.622 20.622 0 0 1 7.083 4.458c1.973 1.896 3.522 4.14 4.647 6.538.561 1.2 1.012 2.44 1.354 3.693.31 1.122.512 2.259.606 3.359h4.907Z'
													fill='#000'
													fill-rule='evenodd'
												/>
											</svg>
										</div>
										{/* TEXT */}
										<div className='py-10 md:p-20'>
											<h4 className='sm:mb-4 paginate-title'>{getInnovation(previousInnovation).title}</h4>
											<div className='hidden leading-normal paginate-text sm:block'>
												{previousInnovation && renderRichText(getInnovation(previousInnovation).excerpt)}
											</div>
										</div>
									</div>
								</Link>
							</div>

							{/* ARROW-RIGTH */}
							<div className='flex flex-row basis-full lg:basis-1/2 '>
								<Link className='w-full' to={urls.innovation + getInnovation(nextInnovation).slug}>
									<div className='flex items-center justify-between'>
										{/* TEXT */}
										<div className='py-10 md:p-20 '>
											<h4 className='sm:mb-4 paginate-title'>{getInnovation(nextInnovation).title}</h4>
											<div className='hidden leading-normal paginate-text sm:block'>
												{nextInnovation && renderRichText(getInnovation(nextInnovation).excerpt)}
											</div>
										</div>
										{/* SVG */}
										<div>
											<svg
												className='h-full w-[37px] md:w-[64px] shrink-0'
												xmlns='http://www.w3.org/2000/svg'
												viewBox='0 0 64 45'
											>
												<path
													d='m36.649.5-1.124.087a23.22 23.22 0 0 0 1.16 4.617 28.022 28.022 0 0 0 1.962 4.299c1.559 2.76 3.615 5.33 6.182 7.477a25.55 25.55 0 0 0 5.443 3.489H.457v4.157h49.815a25.534 25.534 0 0 0-5.443 3.488c-2.567 2.148-4.623 4.717-6.182 7.477a28.055 28.055 0 0 0-1.961 4.3 23.25 23.25 0 0 0-1.16 4.609h6.038a18.67 18.67 0 0 1 .598-3.265 23.106 23.106 0 0 1 1.354-3.693c1.125-2.397 2.674-4.642 4.647-6.538a20.62 20.62 0 0 1 7.083-4.458 25.776 25.776 0 0 1 4.245-1.187A33.778 33.778 0 0 1 64 24.883V20.21a33.84 33.84 0 0 1-4.509-.475 25.851 25.851 0 0 1-4.245-1.188 20.622 20.622 0 0 1-7.083-4.458c-1.973-1.896-3.522-4.14-4.647-6.538-.561-1.2-1.012-2.44-1.354-3.693A18.623 18.623 0 0 1 41.556.5H36.65Z'
													fill='#000'
													fill-rule='evenodd'
												/>
											</svg>
										</div>
									</div>
								</Link>
							</div>
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
}

export default InnovationSingle;

export const query = graphql`
	query InnovationBySlug($slug: String!, $previousSlug: String, $nextSlug: String, $locale: String) {
		contentfulInnovation(slug: { eq: $slug }, node_locale: { eq: $locale }) {
			id
			title
			slug
			metaDescription
			youtubeId
			body {
				raw
			}
			excerpt {
				raw
			}
			image {
				url
				title
			}
			benefits {
				title
				image {
					url
					title
				}
			}
			faqs {
				question
				answer {
					raw
				}
			}
		}
		previous: contentfulInnovation(slug: { eq: $previousSlug }, node_locale: { eq: $locale }) {
			slug
			title
			excerpt {
				raw
			}
		}
		next: contentfulInnovation(slug: { eq: $nextSlug }, node_locale: { eq: $locale }) {
			slug
			title
			excerpt {
				raw
			}
		}
		allContentfulMenu(filter: { node_locale: { eq: $locale } }) {
			edges {
				node {
					...Menus
				}
			}
		}
	}
`;
